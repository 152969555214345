import request from '@/utils/requestEhpc'
const wallet = '/service-wallet-provider/v1'

// 获取活动支付微信的二维码
export function wechatPromotionPay(promotionId) {
  return request({
    url: wallet + '/wx/promotionPay?promotionId=' + promotionId,
    method: 'post'
  })
}
// 获取活动支付支付宝的二维码
export function aliPayPromotionPay(promotionId) {
  return request({
    url: wallet + '/aliPay/promotionPay?promotionId=' + promotionId,
    method: 'post'
  })
}
// 获取活动支付微信的二维码
export function getWechatResult(id) {
  return request({
    url: wallet + '/wx/queryOrder?orderId=' + id,
    method: 'get'
  })
}
// 获取活动支付支付宝的二维码
export function getAliPayResult(id) {
  return request({
    url: wallet + '/aliPay/trade?orderId=' + id,
    method: 'get'
  })
}
// 获取微信支付的二维码
export function getWechatCode(totalAmount) {
  return request({
    url: wallet + '/wx/nativePay?totalAmount=' + totalAmount,
    method: 'post'
  })
}
// 获取支付宝支付的二维码
export function getAliPayCode(totalAmount) {
  return request({
    url: wallet + '/aliPay/pcPay?totalAmount=' + totalAmount,
    method: 'post'
  })
}
// 提交回执单号
export function submitCorporate(number) {
  return request({
    url: wallet + '/corporate',
    method: 'post',
    data: {
      corporateID: number
    }
  })
}
// 查询工单流转状态（只查询最新的前五条）
export function getCorporateState(pageNum, pageSize) {
  return request({
    url: wallet + `/corporate/state?pagenum=${pageNum}&pagesize=${pageSize}`,
    method: 'get'
  })
}
