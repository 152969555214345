<template>
  <div ref="mianscroll" class="recharge">
    <div v-show="authVisible"><Authentication @queryAuthResult="queryAuthResult" /></div>
    <div v-show="!authVisible" class="recharge-main">
      <div class="activity">
        <div class="headers">支付方式</div>
        <div class="payfor-type">
          <!-- 支付宝 -->
          <div class="item" :class="activeKey === 0 ? 'select' : ''" @click="payforChange(0)">
            <div class="shift">
              <img src="@/assets/icon/payfor.png" alt="支付宝">
            </div>
            <div v-show="activeKey === 0" class="select-icon">
              <img src="@/assets/icon/select-icon.png" alt="支付宝">
            </div>
            <div v-show="activeKey === 0" class="select-icons">
              <img src="@/assets/icon/select-icons.png" alt="支付宝">
            </div>
          </div>
          <!-- 微信 -->
          <div class="item" :class="activeKey === 1 ? 'select' : ''" @click="payforChange(1)">
            <div class="shift">
              <img src="@/assets/icon/wechat-pay.png" alt="微信支付">
            </div>
            <div v-show="activeKey === 1" class="select-icon">
              <img src="@/assets/icon/select-icon.png" alt="微信支付">
            </div>
            <div v-show="activeKey === 1" class="select-icons">
              <img src="@/assets/icon/select-icons.png" alt="微信支付">
            </div>
          </div>
          <!-- 对公转账 -->
          <div class="item" :class="activeKey === 2 ? 'select' : ''" @click="payforChange(2)">
            <div class="shift">
              <img src="@/assets/icon/corporate.png" alt="对公转账">
            </div>
            <div v-show="activeKey === 2" class="select-icon">
              <img src="@/assets/icon/select-icon.png" alt="对公转账">
            </div>
            <div v-show="activeKey === 2" class="select-icons">
              <img src="@/assets/icon/select-icons.png" alt="对公转账">
            </div>
          </div>
        </div>
        <!-- 支付宝微信支付金额选择 -->
        <div v-if="!isCorporate">
          <div class="headers">选择金额</div>
          <div class="discount">
            <div v-for="(item,i) in discountList" :key="i" class="item" :class="activeDiscount === i ? 'select' : ''" @click="discountChange(item,i)">
              <div class="discount-bg">
                <img src="@/assets/icon/discount.png" alt="">
              </div>
              <div class="discount-detail">
                {{ item.discount }}
              </div>
              <div class="content">
                <div>{{ item.totalPrice }}元</div>
                <div class="actual-price">券后价{{ item.actualPrice }}元</div>
              </div>
              <div v-show="activeDiscount === i" class="select-icon">
                <img src="@/assets/icon/select-discount.png" alt="">
              </div>
              <div v-show="activeDiscount === i" class="select-icons">
                <img src="@/assets/icon/select-icons.png" alt="">
              </div>
            </div>
            <div v-for="(item,i) in amountList" :key="i" class="item amount" :class="activeAmount === i ? 'amount-select' : ''" @click="amountChange(item,i)">
              <div class="content">
                <div>{{ item.price }}元</div>
                <div class="actual-price">实付{{ item.actualPrice }}元</div>
              </div>
              <div v-show="activeAmount === i" class="select-icon">
                <img src="@/assets/icon/select-icon.png" alt="">
              </div>
              <div v-show="activeAmount === i" class="select-icons">
                <img src="@/assets/icon/select-icons.png" alt="">
              </div>
            </div>
            <div class="item other" @click="otherAmount">
              <div v-if="!isOtherAmountInput">其他金额</div>
              <el-form v-else ref="amountForm" :model="amountForm" :rules="rules" class="demo-ruleForm">
                <el-form-item label="" prop="inputAmount">
                  <el-input ref="input" v-model="amountForm.inputAmount" placeholder="请输入您的充值金额" @input="amountInput" />
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
        <Corporate v-else />
      </div>
      <div v-show="isWechatPayment" class="wechat-code">
        <!-- <img :src="wechatCode" alt="微信支付二维码"> -->
        <div id="qrcode" v-loading="qrcodeLoading" style="width:100%;height:157px;display:flex;justify-content:center;" />
        <div style="margin-top:5px;margin-bottom:24px;">使用微信扫一扫即可付款</div>
        <div v-if="isActivity">
          <span>支付剩余时间：</span>
          <span class="count-down">
            {{
              (leaveTimeHour > 9 ? leaveTimeHour : '0' + leaveTimeHour) +
                ':' +
                (leaveTimeMin > 9 ? leaveTimeMin : '0' + leaveTimeMin) +
                ':' +
                (leaveTimeSecond > 9 ? leaveTimeSecond : '0' + leaveTimeSecond)
            }}
          </span>
        </div>
      </div>
      <div v-show="isSubmit && !isCorporate" class="confirm-btn">
        <el-button :disabled="btnDisabled" type="primary" round @click="confirmRecharge">确认支付<span class="money"> {{ actualPrice }} </span>元</el-button>
      </div>
      <div v-show="rechargeSuccess" class="success-result">
        <div class="success-icon"><img src="@/assets/icon/recharge-success.png" alt=""></div>
        <div class="text">
          <div>恭喜您成功充值！</div>
          <div class="price">您已成功充值{{ totalPrice }}元</div>
        </div>
      </div>
      <div class="tips">
        <div v-show="isFinish" class="query-result">充值完成后，如果长时间没有反应，请点击<span @click="queryResult">支付完成</span></div>
        <div v-show="!isCorporate" class="type">
          <div>支付渠道</div>
          <img class="split" src="@/assets/icon/payfor.png" alt="">
          <img src="@/assets/icon/wechat-pay.png" alt="">
        </div>
        <div class="tips-content">
          <div>温馨提示：1、您选择使用充值功能说明您已阅读<span><router-link target="_blank" to="/rechargeAgreement">《充值协议》</router-link></span>并完全认同该协议内容。</div>
          <div> 2、账户分为真实金额和赠送金额，所有金额余额不可提现</div>
        </div>
      </div>
    <!--实名认证-->
    <!-- <div class="dialog-container">
      <el-dialog
        v-model="authVisible"
        title="实名认证"
        :close-on-click-modal="false"
        width="35%"
      >
        <div class="dialog-content">
          <div class="base-info">
            <Authentication @queryAuthResult="queryAuthResult" />
          </div>
        </div>
      </el-dialog>
    </div> -->
    </div>
  </div>

</template>

<script>
import Authentication from '@/components/Authentication.vue'
import QRCode from 'qrcodejs2'
import { ElMessage } from 'element-plus'
import {
  wechatPromotionPay,
  aliPayPromotionPay,
  getWechatResult,
  getAliPayResult,
  getWechatCode,
  getAliPayCode
} from '@/api/wallet'
import { mapGetters } from 'vuex'
import Corporate from '../components/Corporate.vue'
// import validate from '@/utils/baseValidate.js'
export default {
  components: { Corporate, Authentication },
  data() {
    const validateAmount = (rule, value, callback) => {
      const reg = /(^[1-9](\d+)?(\.\d{1,2})?$)|(^0$)|(^\d\.\d{1,2}$)/
      const regLimit = /^\d{0,7}(\.\d{0,2})?$/g
      if (value === '' || value === null) {
        this.amountForm.inputAmount = null
        this.isSubmit = false
        this.totalAmount = this.amountForm.inputAmount
        this.actualPrice = this.totalAmount
        this.totalPrice = this.totalAmount
        callback(new Error('请输入您的充值金额'))
      } else {
        if (reg.test(value) === false) {
          callback(new Error('请输入正确的金额'))
        } else if (value == 0) {
          callback(new Error('请输入正确的金额'))
        } else if ((Number(value) > 10000 && this.activeKey === 1)) {
          callback(new Error('微信充值金额不能超过一万元'))
        } else if (Number(value) > 10000 * 20 && this.activeKey === 0) {
          callback(new Error('支付宝充值金额不能超过二十万元'))
        } else {
          callback()
        }
      }
    }
    return {
      authVisible: false,
      successAmount: null,
      amountForm: {
        inputAmount: null
      },
      rules: {
        inputAmount: [{ required: true, trigger: 'blur', validator: validateAmount }]
      },
      codeShowAmount: null,
      isOtherAmountInput: false,
      totalAmount: null,
      inputAmount: null,
      activeAmount: -1,
      amountList: [{
        price: 100,
        actualPrice: 100,
        id: 1
      }, {
        price: 500,
        actualPrice: 500,
        id: 2
      }, {
        price: 1000,
        actualPrice: 1000,
        id: 3
      }, {
        price: 5000,
        actualPrice: 5000,
        id: 4
      }, {
        price: 10000,
        actualPrice: 10000,
        id: 5
      }],
      isActivity: true,
      totalPrice: null,
      actualPrice: null,
      isSubmit: false,
      btnDisabled: false,
      qrcode: null,
      isFinish: false,
      // 支付宝支付-订单id
      zfbOrderId: '',
      wxOrderId: '',
      qrcodeLoading: true,
      isWechatPayment: false,
      rechargeSuccess: false,
      wechatCode: '',
      activeKey: 0,
      activeDiscount: -1,
      discountList: [{
        discount: '满10减9',
        totalPrice: 10,
        actualPrice: 1,
        promotionId: 3879831925655912
      }],
      promotionId: null,
      timer: null,
      endTime: '',
      // 时
      leaveTimeHour: '',
      // 分
      leaveTimeMin: '',
      // 秒
      leaveTimeSecond: '',
      // 定时器
      countDownTimer: null,
      isCorporate: false // 选择对公转账
    }
  },
  computed: {
    ...mapGetters([
      'auth'
    ])
  },
  beforeUnmount() {
    clearInterval(this.timer)
    this.timer = null
    this.clearTimer()
  },
  methods: {
    // 认证结果
    queryAuthResult(data) {
      if (data === 'success') {
        this.authVisible = false
      }
    },
    // 其他金额
    otherAmount() {
      this.isOtherAmountInput = true
      this.activeAmount = -1
      this.activeDiscount = -1
      this.isFinish = false
      this.rechargeSuccess = false
      this.isWechatPayment = false
      this.btnDisabled = false
      this.$nextTick(() => {
        this.$refs.input.focus()
      })
      if (this.$refs.amountForm === undefined) {
        this.isSubmit = false
      } else {
        if (this.amountForm.inputAmount === null) {
          this.isSubmit = false
          return
        }
        this.$refs.amountForm.validate((valid) => {
          if (valid) {
            this.isSubmit = true
            this.isWechatPayment = false
            this.clearTimer()
            this.isFinish = false
            if (this.timer != null) {
              clearInterval(this.timer)
              this.timer = null
            }
          } else {
            this.isSubmit = false
          }
        })
      }
    },
    // 输入金额时
    amountInput(val) {
      if (this.$refs.amountForm !== undefined) {
        this.$refs.amountForm.validate((valid) => {
          if (valid) {
            if (this.activeKey !== 2) {
              this.totalAmount = this.amountForm.inputAmount
              this.actualPrice = this.totalAmount
              this.totalPrice = this.totalAmount
              this.isWechatPayment = false
              this.clearTimer()
              this.isActivity = false
              this.isSubmit = true
              this.isFinish = false
            } else {
              this.isSubmit = false
              this.totalAmount = this.amountForm.inputAmount
              this.actualPrice = this.totalAmount
              this.totalPrice = this.totalAmount
              this.isWechatPayment = false
              this.clearTimer()
            }
          } else {
            this.isSubmit = false
          }
        })
      } else {
        this.isSubmit = false
      }
    },
    // 支付方式选择
    payforChange(val) {
      this.activeKey = val
      this.isWechatPayment = false
      this.clearTimer()
      this.rechargeSuccess = false
      this.isFinish = false
      this.btnDisabled = false
      if (this.timer != null) {
        clearInterval(this.timer)
        this.timer = null
      }
      if (this.isActivity) {
        if (this.activeKey === 2) {
          this.isCorporate = true
        } else {
          this.isCorporate = false
        }
        if (this.activeKey !== 2 && this.activeDiscount !== -1) {
          this.isSubmit = true
        }
      } else {
        if (this.activeKey === 2) {
          this.isCorporate = true
        } else {
          this.isCorporate = false
        }
        if (this.isOtherAmountInput) {
          this.$refs.amountForm.validate((valid) => {
            if (!valid) {
              return
            } else {
              this.actualPrice = this.totalAmount
              this.isSubmit = true
            }
          })
        } else {
          this.actualPrice = this.totalAmount
          this.isSubmit = true
        }
      }
    },
    // 实际支付金额选择
    amountChange(item, i) {
      this.isActivity = false
      this.actualPrice = item.actualPrice
      this.totalAmount = item.actualPrice
      this.activeAmount = i
      this.isWechatPayment = false
      this.clearTimer()
      this.rechargeSuccess = false
      this.btnDisabled = false
      this.isFinish = false
      this.isOtherAmountInput = false
      this.activeDiscount = -1
      this.amountForm.inputAmount = null
      if (this.timer != null) {
        clearInterval(this.timer)
        this.timer = null
      }
      if (this.activeKey !== 2 && this.activeAmount !== -1) {
        this.isSubmit = true
      }
    },
    // 优惠方式选择
    discountChange(item, i) {
      this.isActivity = true
      this.actualPrice = item.actualPrice
      this.totalPrice = item.totalPrice
      this.activeDiscount = i
      this.isWechatPayment = false
      this.clearTimer()
      this.rechargeSuccess = false
      this.btnDisabled = false
      this.isFinish = false
      this.isOtherAmountInput = false
      this.amountForm.inputAmount = null
      this.promotionId = item.promotionId
      this.activeAmount = -1
      if (this.timer != null) {
        clearInterval(this.timer)
        this.timer = null
      }
      if (this.activeKey !== 2 && this.activeDiscount !== -1) {
        this.isSubmit = true
      }
    },
    // 充值按钮
    confirmRecharge() {
      if (this.auth != 1) {
        this.authVisible = true
        return
      }
      if (this.isActivity) {
        if (this.activeDiscount === -1) {
          ElMessage.warning('请选择充值金额')
          return
        }
        if (this.activeKey === 0) {
          this.btnDisabled = true
          this.aliPayPayment()
        } else if (this.activeKey === 1) {
          this.btnDisabled = true
          this.wechatPayment()
        } else {
          ElMessage.warning('请选择支付方式')
        }
      } else {
        if (this.activeKey === 0) {
          this.btnDisabled = true
          this.aliPayPayment()
        } else if (this.activeKey === 1) {
          this.btnDisabled = true
          this.wechatPayment()
        } else {
          ElMessage.warning('请选择支付方式')
        }
      }
    },
    // 充值结果查询
    queryResult() {
      if (this.activeKey === 0) {
        this.queryAliPayResult()
      }
      if (this.activeKey === 1) {
        this.queryWechatResult()
      }
    },
    // 微信支付
    wechatPayment() {
      // this.isWechatPayment = true
      this.qrcodeLoading = true
      if (this.isActivity) {
        this.codeShowAmount = this.totalPrice
        wechatPromotionPay(this.promotionId).then((res) => {
          if (!this.isActivity || this.isOtherAmountInput) {
            this.btnDisabled = false
            return
          }
          if (res.meta.status === 200) {
            if (this.codeShowAmount !== this.totalPrice) {
              return
            }
            const data = res.data
            if (data.method === 'wxpay') {
              this.wechatCodeShow(data)
              this.endTime = data.expireTime
              this.methodCountDown()
              this.countDownTimer = setInterval(() => {
                this.methodCountDown()
              }, 1000)
            } else {
              this.activeKey = 0
              ElMessage.warning('您有未完成的支付宝支付订单，请先完成！')
              setTimeout(() => {
                this.aliPayCodeShow(data)
              }, 1000)
            }
          } else {
            this.isWechatPayment = false
            this.clearTimer()
            this.isFinish = false
            if (this.timer != null) {
              clearInterval(this.timer)
              this.timer = null
            }
          }
        }).catch(() => {
          this.isWechatPayment = false
          this.clearTimer()
          this.isFinish = false
          if (this.timer != null) {
            clearInterval(this.timer)
            this.timer = null
          }
        })
      } else {
        this.codeShowAmount = this.totalAmount
        getWechatCode(this.totalAmount).then((res) => {
          if (res.meta.status === 200) {
            if (this.isActivity) {
              this.btnDisabled = false
              return
            }
            if (this.isOtherAmountInput) {
              if (this.codeShowAmount !== this.amountForm.inputAmount) {
                this.btnDisabled = false
                return
              }
            }
            if (this.totalAmount !== this.codeShowAmount) {
              this.btnDisabled = false
              return
            }
            const data = res.data
            this.wxOrderId = data.orderId
            document.getElementById('qrcode').innerHTML = ''
            this.qrcode = new QRCode(document.getElementById('qrcode'), {
              text: data.code_url,
              width: 157,
              height: 157,
              colorDark: '#000',
              colorLight: '#fff',
              correctLevel: QRCode.CorrectLevel.H
            })
            this.isFinish = true
            this.qrcodeLoading = false
            this.btnDisabled = false
            this.isSubmit = false
            this.isWechatPayment = true
            this.$nextTick(() => {
              const scrollEl = this.$refs.mianscroll
              scrollEl.scrollTo({ top: scrollEl.scrollHeight, behavior: 'smooth' })
            })
            const that = this
            this.timer = setInterval(() => {
              that.queryWechatIsSuccess()
            }, 5000)
          } else {
            this.btnDisabled = false
            this.isWechatPayment = false
            this.clearTimer()
            this.isFinish = false
            if (this.timer != null) {
              clearInterval(this.timer)
              this.timer = null
            }
          }
        }).catch(() => {
          this.btnDisabled = false
          this.isWechatPayment = false
          this.clearTimer()
          this.isFinish = false
          if (this.timer != null) {
            clearInterval(this.timer)
            this.timer = null
          }
        })
      }
    },
    // 微信支付二维码显示
    wechatCodeShow(data) {
      this.wxOrderId = data.orderId
      document.getElementById('qrcode').innerHTML = ''
      this.qrcode = new QRCode(document.getElementById('qrcode'), {
        text: data.code_url,
        width: 157,
        height: 157,
        colorDark: '#000',
        colorLight: '#fff',
        correctLevel: QRCode.CorrectLevel.H
      })
      this.isFinish = true
      this.qrcodeLoading = false
      this.btnDisabled = false
      this.isSubmit = false
      this.isWechatPayment = true
      this.$nextTick(() => {
        const scrollEl = this.$refs.mianscroll
        scrollEl.scrollTo({ top: scrollEl.scrollHeight, behavior: 'smooth' })
      })
      const that = this
      this.timer = setInterval(() => {
        that.queryWechatIsSuccess()
      }, 5000)
    },
    // 微信充值结果查询
    queryWechatResult() {
      getWechatResult(this.wxOrderId).then(res => {
        if (res.meta.status === 200) {
          if (res.data) {
            ElMessage.success('充值成功')
            this.isWechatPayment = false
            this.clearTimer()
          } else {
            ElMessage.warning('未查询到充值信息，请稍后再试')
          }
        }
      }).catch(() => {
        ElMessage.warning('未查询到充值信息，请稍后再试')
      })
    },
    // 循环成功接口
    queryWechatIsSuccess() {
      getWechatResult(this.wxOrderId).then(res => {
        if (res.meta.status === 200) {
          if (res.data) {
            this.rechargeSuccess = true
            this.isWechatPayment = false
            this.clearTimer()
            this.isFinish = false
            if (this.timer != null) {
              clearInterval(this.timer)
              this.timer = null
            }
          }
        }
      })
    },
    // 支付宝支付
    aliPayPayment() {
      this.isWechatPayment = false
      this.clearTimer()
      if (this.isActivity) {
        aliPayPromotionPay(this.promotionId).then(res => {
          console.log('aliPayPromotionPay2')
          if (res.meta.status === 200) {
            const data = res.data
            if (data.method === 'wxpay') {
              this.activeKey = 1
              ElMessage.warning('您有未完成的微信支付订单，请先完成！')
              this.wechatCodeShow(data)
              this.endTime = data.expireTime
              this.methodCountDown()
              this.countDownTimer = setInterval(() => {
                this.methodCountDown()
              }, 1000)
            } else {
              this.aliPayCodeShow(data)
            }
          } else {
            this.isFinish = false
            if (this.timer != null) {
              clearInterval(this.timer)
              this.timer = null
            }
          }
        }).catch(() => {
          console.log('catch aliPayPromotionPay2')
          this.isFinish = false
          if (this.timer != null) {
            clearInterval(this.timer)
            this.timer = null
          }
        })
      } else {
        this.codeShowAmount = this.totalAmount
        getAliPayCode(this.totalAmount).then(res => {
          if (this.codeShowAmount !== this.totalAmount) {
            return
          }
          this.isFinish = true
          this.isSubmit = false
          this.btnDisabled = false
          const data = res.data
          this.zfbOrderId = data.orderId
          const routeData = this.$router.resolve({ path: '/zfbPayment', query: { htmls: data.form }})
          window.open(routeData.href, '_blank')
          this.$nextTick(() => {
            const scrollEl = this.$refs.mianscroll
            scrollEl.scrollTo({ top: scrollEl.scrollHeight, behavior: 'smooth' })
          })
          const that = this
          this.timer = setInterval(() => {
            that.queryAliPayIsSuccess()
          }, 5000)
        }).catch(() => {
          this.isFinish = false
          if (this.timer != null) {
            clearInterval(this.timer)
            this.timer = null
          }
        })
      }
    },
    // 支付宝二维码显示
    aliPayCodeShow(data) {
      this.isFinish = true
      this.isSubmit = false
      this.btnDisabled = false
      this.zfbOrderId = data.orderId
      const routeData = this.$router.resolve({ path: '/zfbPayment', query: { htmls: data.form }})
      window.open(routeData.href, '_blank')
      this.$nextTick(() => {
        const scrollEl = this.$refs.mianscroll
        console.log('scrollEl' + scrollEl)
        scrollEl.scrollTo({ top: scrollEl.scrollHeight, behavior: 'smooth' })
      })
      const that = this
      this.timer = setInterval(() => {
        that.queryAliPayIsSuccess()
      }, 5000)
    },
    // 循环成功接口
    queryAliPayIsSuccess() {
      getAliPayResult(this.zfbOrderId).then(res => {
        if (res.meta.status === 200) {
          if (res.data) {
            this.rechargeSuccess = true
            this.isFinish = false
            if (this.timer != null) {
              clearInterval(this.timer)
              this.timer = null
            }
          }
        }
      })
    },
    // 支付宝充值结果查询
    queryAliPayResult() {
      getAliPayResult(this.zfbOrderId).then(res => {
        if (res.meta.status === 200) {
          if (res.data) {
            ElMessage.success('充值成功')
          } else {
            ElMessage.warning('未查询到充值信息，请稍后再试')
          }
        }
      }).catch(() => {
        ElMessage.warning('未查询到充值信息，请稍后再试')
      })
    },
    // 清除倒计时定时器
    clearTimer() {
      if (this.countDownTimer != null) {
        clearInterval(this.countDownTimer)
        this.countDownTimer = null
      }
    },
    // 计算倒计时
    methodCountDown() {
      const endDate = this.endTime
      const now = parseInt(new Date().getTime() / 1000) + ''
      const leaveTime = (endDate - now)
      this.leaveTimeHour = parseInt(leaveTime / 60 / 60) % 24
      this.leaveTimeMin = parseInt(leaveTime / 60) % 60
      this.leaveTimeSecond = parseInt(leaveTime % 60)
      // 如果倒计时小于等于0时显示0,并清除定时器
      if (
        this.leaveTimeHour <= 0 &&
        this.leaveTimeMin <= 0 &&
        this.leaveTimeSecond <= 0
      ) {
        this.leaveTimeHour = 0
        this.leaveTimeMin = 0
        this.leaveTimeSecond = 0
        this.clearTimer()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.recharge{
  height: 100%;
  width: 100%;
  // min-width: 660px;
  overflow: auto;
  ::v-deep .el-card.is-always-shadow{
    box-shadow: none;
  }
  ::v-deep .el-card{
    border: none;
  }
}
.recharge-main{
  padding: 20px 25px 30px 45px;
  height: 100%;
  width: 100%;
  .recharge-input{
    min-width: 590px;
    /deep/.el-form-item__label, .title {
      font-size: 16px;
      font-weight: bold;
      color: #595F67;
      padding: 0;
    }
    .total-amount{
      margin-bottom: 30px;
      .el-input{
        width: 200px;
        margin-left: 16px;
      }
      /deep/.el-input__inner{
        height: 32px;
        line-height: 32px;
        padding: 0 8px;
      }
    }
    .type{
      display: flex;
      .title {
        padding-right: 16px;
        padding-left: 45px;
      }
    }
  }
  .tips{
    background-color: #fff;
    // position: absolute;
    // bottom: 0;
    // left: 0;
    // width: 100%;
    // min-width: 660px;
    // padding-top: 10px;
    // padding-bottom: 30px;
    padding: 30px 30px 10px;
    text-align: center;
    .query-result{
      padding-bottom: 16px;
      span{
        color: #437AEC;
        cursor: pointer;
        font-weight: 500;
      }
    }
    .tips-content{
      color: #606266;
      font-size: 12px;
      margin-top: 16px;
      line-height: 20px;
    }
    .type{
      display: flex;
      justify-content: center;
      align-items: center;
      .split{
        margin-left: 24px;
        margin-right: 8px;
      }
    }
  }
  .success-result{
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 50px;
    img{
      width: 60px;
      height: 60px;
    }
    .text{
      font-size: 22px;
      font-weight: 500;
      color: rgba(0,0,0,0.9);
      padding-left: 16px;
      .price{
        margin-top: 8px;
        font-size: 16px;
        font-weight: 400;
        color: rgba(0,0,0,0.6);
        line-height: 24px;
      }
    }
  }
  .confirm-btn{
    width: 100%;
    text-align: center;
    padding-top: 40px;
    ::v-deep .el-button.is-round{
      padding: 12px 55px;
      font-size: 16px;
      border-radius: 31px;
      height: 54px;
      color: #FFFFFF;
      .money{
        font-size: 28px;
      }
    }
    ::v-deep .el-button.is-round:hover {
      box-shadow: 3px 3px 6px 0 rgba(26, 53, 108, 0.2);
    }
  }
  .wechat-code{
    text-align: center;
    color: #000000;
    font-size: 14px;
    margin-bottom: 20px;
    img{
      width: 157px;
      height: 157px;
      margin-bottom: 5px;
    }
    .count-down{
      font-size: 18px;
      font-weight: 500;
      color: #F02E3E;
    }
  }
  .headers{
    color: #595F67;
    font-size: 16px;
    font-weight: bold;
    color: #595F67;
    line-height: 24px;
    padding-bottom: 11px;
  }
  .discount{
    display: flex;
    flex-wrap: wrap;
    margin-top: 11px;
    .content{
      font-size: 22px;
      font-weight: 500;
      color: #303133;
      line-height: 26px;
      .actual-price{
        font-weight: 400;
        color: #FF711D;
        line-height: 22px;
        padding-top: 2px;
        font-size: 14px;
      }
    }
    .discount-bg{
      position: absolute;
      left: -1px;
      top: -15px;
    }
    .discount-detail{
      position: absolute;
      left: -1px;
      top: -15px;
      width: 136px;
      text-align: center;
      line-height: 29px;
      font-size: 14px;
      font-weight: 500;
      color: #FFFFFF;
    }
    .item {
      margin-bottom: 25px;
      text-align: center;
      padding-top: 26px;
      border: 1px #e7e7e7 solid;
      width: 200px;
      height: 90px;
      border-radius: 4px;
      margin-right: 16px;
      position: relative;
    }
    .other{
      padding-top: 30px;
      font-size: 16px;
      color: #606266;
      background: #F8F8F8;
      .el-input{
        --el-input-background-color: #F8F8F8;
        --el-input-focus-border: none;
        --el-input-border: none;
      }
      ::v-deep .el-input__inner{
        text-align: center;
        box-shadow: none;
        background-color: transparent;
      }
      ::v-deep .el-input__wrapper{
        box-shadow: none;
        background-color: transparent;
      }
      ::v-deep .el-form-item.is-error .el-input__inner, .el-form-item.is-error .el-input__inner:focus, .el-form-item.is-error .el-select-v2__wrapper, .el-form-item.is-error .el-select-v2__wrapper:focus, .el-form-item.is-error .el-textarea__inner, .el-form-item.is-error .el-textarea__inner:focus{
        box-shadow: none;
        background-color: transparent;
      }
      ::v-deep .el-input__inner:focus{
        box-shadow: none;
        background-color: transparent;
      }
    }
    .select, .amount-select {
      position: relative;
      color: #FF711D;
      background-color: #fff;
      border-radius: 4px;
      border: 1px solid #FF711D;
      font-size: 0;
    }
    .amount-select{
      color: #437AEC;
      border: 1px solid #437AEC;
    }
    .select-icon{
      position: absolute;
      right: 0;
      bottom: 0;
      display: table-cell;
      img{
        display: block;
        width: 34px;
        height: 36px;
      }
    }
    .select-icons{
      position: absolute;
      right: 2px;
      bottom: 2px;
      display: table-cell;
      img{
        display: block;
        width: 16px;
        height: 16px;
      }
    }
    .amount-selectayfor-type{
      color: #437AEC;
      border: 1px solid #437AEC;
    }
    .amount{
      .actual-price{
        color: #437AEC;
      }
    }
  }
  .payfor-type{
    display: flex;
    margin-bottom: 24px;
    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px #e7e7e7 solid;
      width: 200px;
      height: 90px;
      border-radius: 4px;
      margin-right: 16px;
    }
    .select {
      position: relative;
      color: #437AEC;
      background-color: #fff;
      border-radius: 4px;
      border: 1px solid #437AEC;
      font-size: 0;
    }
    .select-icon{
      position: absolute;
      right: 0;
      bottom: 0;
      display: table-cell;
      img{
        display: block;
        width: 34px;
        height: 36px;
      }
    }
    .select-icons{
      position: absolute;
      right: 2px;
      bottom: 2px;
      display: table-cell;
      img{
        display: block;
        width: 16px;
        height: 16px;
      }
    }
  }
}
</style>
