<template>
  <div class="corporate">
    <div class="corporate-content">
      <p>
        1、线下对公汇款预期1~5个工作日到账，具体到账时间以银行的实际到账时间为准;
      </p>
      <p>
        2、为防范可能发生的诈骗、洗钱等风险，我方将进一步核查及审慎判断，并且有权拒绝存在异常情形的相关汇款。
      </p>
      <p>3、请在汇款到账后进行汇款认领操作。</p>
    </div>
    <!-- 输入回执单号 -->
    <div v-if="isReceipt" class="corporate-search">
      <div class="search-content">
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label-width="0">
            <el-input v-model="form.receiptNum" placeholder="请输入汇款回执单号">
              <template #append>
                <el-button type="primary" :disabled="isDisabled" @click="submitReceipt">提交</el-button>
              </template>
            </el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 查询到回执单号 -->
    <div v-if="isClaimProgress" class="receipt">
      <div v-loading="loading" class="receipt-content">
        <el-table
          :data="receiptData"
          :cell-style="{ height: '46px' }"
          :header-cell-style="{
            backgroundColor: '#F7F8FA',
            fontSize: '14px',
            fontWeight: '400',
            color: '#303133',
          }"
        >
          <el-table-column
            prop="corporate_id"
            label="回执单号"
          />
          <el-table-column
            prop="create_time"
            label="认领日期"
          />
          <el-table-column prop="state" label="当前状态" width="80">
            <template #default="scope">
              <span v-if="scope.row.state === '已审批'|| scope.row.state === '已处理'" class="fulfill">已到账</span>
              <span v-else-if="scope.row.state === '已撤销' || scope.row.state === '已拒绝'" class="error">未通过</span>
              <span v-else class="running">处理中</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 汇款认领 -->
    <div v-if="isRemittanceClaim" class="remittance-claim">
      <div class="remittance-claim-top">
        <div class="remittance-row">
          <span>单位名称</span>
          <span>山东正云信息科技有限公司</span>
        </div>
        <div class="remittance-row">
          <span>开户银行</span>
          <span>齐鲁银行济南政务服务中心支行</span>
        </div>
        <div class="remittance-row">
          <span>开户银行代码</span>
          <span>313451007801</span>
        </div>
        <div class="remittance-row">
          <span>账号</span>
          <span>86611780101421004468</span>
        </div>
      </div>
      <div class="remittance-claim-bottom">
        <button class="remittance-btn" @click="remittanceClaim">
          汇款认领
        </button>
        <button class="remittance-btn" @click="claimProgress">认领进度</button>
      </div>
    </div>
  </div>
</template>

<script>
import { submitCorporate, getCorporateState } from '@/api/wallet'
import { ElMessage } from 'element-plus'
export default {
  name: 'Corporate',
  data() {
    return {
      isRemittanceClaim: true, // 汇款认领
      isClaimProgress: false,
      isReceipt: false,
      receiptNum: '', // 回执单号
      pageNum: 1,
      pageSize: 5,
      receiptData: [],
      loading: true,
      isDisabled: false,
      form: {
        receiptNum: ''
      }
    }
  },
  methods: {
    // 汇款认领
    remittanceClaim() {
      this.isReceipt = true
      this.isRemittanceClaim = false
    },
    // 认领进度
    claimProgress() {
      this.isRemittanceClaim = false
      this.isClaimProgress = true
      this.corporateState()
    },
    // 提交回执单号
    submitReceipt() {
      if (this.isDisabled) return
      // const reg = /^[A-Za-z0-9]{1,100}$/
      if (this.form.receiptNum.length > 200 || !this.form.receiptNum) {
        return ElMessage({
          type: 'error',
          message: '请输入正确的回执单号'
        })
      }
      this.isDisabled = true
      submitCorporate(this.form.receiptNum).then(res => {
        if (res.meta.status === 201) {
          this.form.receiptNum = ''
          this.isReceipt = false
          this.isClaimProgress = true
          this.isDisabled = false
          this.corporateState()
          return ElMessage({
            type: 'success',
            message: '提交成功'
          })
        }
      }).catch(err => {
        console.log(err)
        this.form.receiptNum = ''
        this.isDisabled = false
      })
    },
    // 查询工单流转状态
    corporateState() {
      getCorporateState(this.pageNum, this.pageSize).then(res => {
        if (res.meta.status === 200) {
          this.loading = false
          this.receiptData = res.data.content
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.corporate {
  .corporate-content {
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #606266;
    line-height: 20px;
    margin-top: 8px;
    p {
      margin: 0;
    }
  }
  .corporate-search {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 60px 0 200px 0;
    .search-content {
      width: 392px;
      /deep/ .el-input-group__append {
        background: #437aec !important;
        color: #ffffff;
        font-size: 14px;
        // overflow: hidden;
      }
      /deep/ .el-input__inner {
        background: #f7f8fa !important;
      }
      /deep/ .el-input-group__append {
        padding: 0 !important;
      }
    }
  }
  .receipt {
    display: flex;
    align-items: center;
    justify-content: center;
    .receipt-content {
      width: 566px;
      height: 288px;
      overflow: auto;
      margin-top: 40px;
      padding: 0 20px 0 20px;
      background-color: #f7f8fa;
      border-radius: 5px;
      ::v-deep .el-table__expanded-cell {
        background-color: #f7f8fa !important;
        color: #606266 !important;
      }
      ::v-deep .el-table th,
      ::v-deep .el-table tr,
      ::v-deep .el-table td {
        background-color: #f7f8fa;
        font-size: 14px;
        color: #606266 !important;
      }
      ::v-deep .el-table tr:last-child td.el-table__cell,
      .el-table th.el-table__cell.is-leaf {
        border-bottom: none;
      }
      .running {
        color: #165dff;
        font-weight: 400;
      }
      .fulfill {
        color: #00a870;
        font-weight: 400;
      }
      .error {
        color: #ff3a3a;
        font-weight: 400;
      }
    }
    .receipt-content::-webkit-scrollbar {
      display: none;
    }
  }
  .remittance-claim {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 40px;
    .remittance-claim-top {
      width: 408px;
      height: 240px;
      padding: 8px 24px 0 24px;
      box-sizing: border-box;
      background-color: #f7f8fa;
      border-radius: 5px;
      .remittance-row {
        padding: 16px 0 16px 0;
        width: 100%;
        box-sizing: border-box;
        border-bottom: 1px solid #e7e7e7;
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 400;
        color: #606266;
        span:nth-child(1) {
          flex: 0.5;
        }
        span:nth-child(2) {
          text-align: left;
          flex: 1;
          color: #303133;
        }
      }
      .remittance-row:last-child {
        border-bottom: none;
      }
    }
    .remittance-claim-bottom {
      margin-top: 32px;
      width: 408px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 100px 0 100px;
      box-sizing: border-box;
      .remittance-btn {
        background-color: #437aec;
        padding: 6px 16px 6px 16px;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        border-radius: 3px;
      }
    }
  }
}
</style>
